
.collapsible-body{
    padding: 0 50px;
}
.side-nav .collapsible-header, .side-nav.fixed .collapsible-header{
    padding: 0 32px;
}

li img{
    float: left;
    height: 30px;
    line-height: 48px;
    margin: 0 32px 0 0;
    margin-top: 8px;
}


@media only screen and (max-width : 992px) {
    .Sidenav{
        padding-left: 0;
        height: 100vh;
    }
    .side-nav {
        background-color: #F0F8FF;
        top: 0px;
    }
    .side-nav .collapsible-header, .side-nav.fixed .collapsible-header{
        padding: 0 16px;
    }

}
.row .col{
    padding: 0;
}
/*#username{*/
    /*padding-left: 30px;*/
    /*line-height: 1.5;*/
    /*margin-top: -3px;*/
/*}*/
.side-nav {
    background-color: #F0F8FF;
    top: 65px;
}
.side-nav a{
    display: flex;
    align-items: center;
}

@media only screen and (min-width : 992px) {
}

#username .name{
    font-size: 2em;
}