nav {
    background-color: #1976D2;
}

.Sidenav {
    padding-left: 260px;
    /* height:100vh; */
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.padding15 {
    padding: 15px;
}

.width-100 {
    width: 100px;
}

.justify-content-flex-end {
    justify-content: flex-end !important;
}

.leftMargin10 {
    margin: 0 10px 0 0;
}

.account-group-label-div {
    padding: 15px 20px 0 20px !important;
}

.floatRight {
    float: right;
}

.word-break-all {
    word-break: break-all;
}

.future-label-account {
    display: flex !important;
    justify-content: flex-end;
    font-size: 0.8rem !important;
}

.future-label-account>p {
    margin: 0 !important;
    background-color: #ffeb3b !important;
    color: #303030 !important;
    padding: 0 1px;
}

.amount-symbol {
    font-weight: bold;
}

.progress-bar-control {
    width: inherit !important;
}

.min-width-70 {
    min-width: 70px !important;
}

.line-height-1point5 {
    line-height: 1.5 !important;
    font-size: 1.4rem !important;
}

.account-group-label-balance>span {
    font-size: 1.5rem !important;
    font-weight: bold !important;
}

.account-balance {
    font-size: 1rem !important;
    font-weight: bold !important;
}

.empty-span-accounts {
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 5px;
    display: inline-block;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.border-bottom-1 {
    border-bottom: 1px solid #e0e0e0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.green-button {
    background-color: #8bc34a !important;
}

.user-options {
    float: right;
    padding-right: 15px;
    background: none;
}

div.user-options button {
    background: none;
    text-transform: none;
}

div.user-options button i {
    line-height: 35px;
    height: 35px;
}

ul.dropdown-content li {
    min-height: 15px;
    padding: 6px;
}

ul.dropdown-content li.divider {
    padding: 0;
}

ul.dropdown-content li i.material-icons {
    float: left;
    line-height: 23px;
    margin-right: 5px;
    height: 23px;
}

.user-account-icon {
    margin: 0;
    vertical-align: middle;
    margin-top: 18px;
    margin-right: 5px;
}

.input-field select {
    border: none;
    border-bottom: 1px solid gray;
}

.collection-item span.leftBadge {
    float: left;
    margin-right: 14px;
    margin-left: 0;
    min-width: 1.5rem;
    border-radius: 11px;
}

nav .brand-logo {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

nav .brand-logo .logo_text {
    color: white;
    font-size: 28px;
    margin-left: 5px;
    vertical-align: middle;
}

.search {
    box-sizing: border-box;
    background-color: #7CA4EF;
    border: 0.5px solid gray;
    height: 30px;
    width: 50%;
    color: white;
}

#search {
    max-width: 0;
    width: 800px;
    color: black;
    background-color: white;
    margin-bottom: 0;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    box-sizing: border-box;
    opacity: 0;
    height: 40px;
    border-radius: 6px;
}

#search.expanded {
    max-width: 500px;
    opacity: 1;
}

.search:hover {
    background-color: #7CA4EF;
}


@media only screen and (max-width : 992px) {
    .Sidenav {
        padding-left: 0;
        height: calc(100vh - 56px);
    }

    nav .brand-logo {
        margin-left: 40px;
        left: 110px;
    }
}

@media (max-width: 640px) {
    nav .brand-logo {
        margin-left: 30px;
        left: 75px;
    }

    nav .brand-logo img {
        height: 33px !important;
    }

    nav .brand-logo .logo_text {
        font-size: 20px;
    }

    nav ul a {
        padding: 0 15px 0 5px;
    }
}

.card.darken-1 {
    word-break: break-all;
}

.orLink {
    margin-top: 20px;
    font-size: large;
}

.social-icons li {
    display: inline-block;
    padding: 0 15px;
}

@media only screen and (min-width : 600px) {
    .orLink {
        text-align: right;
        font-size: medium;
    }
}

.dropdown-content li>span {
    color: #000000;
}

.day-round {
    background: orange;
    line-height: 50px;
    text-align: center;
    border-radius: 56px;
    font-size: 20px;
    width: 50px;
    margin-right: 15px;
}

.tab-title {
    line-height: normal;
    padding-top: 7px;
    text-transform: uppercase;
}

.red-text {
    color: red;
}

.floating-patch {
    float: left;
    width: 100%;
}

.bill-grey-month {
    background-color: #d2d5d8;
    color: #000000;
    padding-left: 5px;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    /* background: black url(spinner.gif) center center no-repeat; */
    opacity: .5;
}

.loader {
    position: fixed;
    left: 50%;
    top: 35%;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.loader-box {
    position: fixed;
    left: 50%;
    top: 35%;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bill-grey-text {
    color: #d2d5d8;
    padding-left: 5px;
}

.grey-disclaimer {
    color: #797979;
}

.padding-10 {
    padding: 10px;
}

.dark-grey-text {
    color: #797979 !important;
}

.border-top-0 {
    border-top: 0 !important;
    margin-top: 0 !important;
}

.date-label {
    display: block;
    line-height: 32px;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 300;
}

table.no-padding td {
    padding: 0;
}

.categories {
    overflow: hidden;
    text-overflow: ellipsis;
}

.row.mb-0 {
    margin-bottom: 0;
}

.fix-height-tab {
    height: 400px;
    overflow-y: auto;
}

.tabs .tab a {
    color: rgba(134, 129, 129, 0.7);
}

.tabs .tab a:hover,
.tabs .tab a.active {
    background-color: transparent;
    color: #000000;
}

.bottom-check {
    margin: 0;
    height: 15px;
    position: relative;
    bottom: 10px;
    left: 21px;
}

.overlap-tab-filter {
    padding: 0 10px;
    position: absolute;
    right: 0;
    z-index: 99;
    text-align: center;
}

.overlap-tab-filter .input-field {
    margin-top: 0;
}

.year-selector {
    display: inline-flex;
}

.year-selector img {
    padding-left: 4px;
}

.year-selector i.material-icons {
    cursor: pointer;
}

.year-selector div.value {
    margin-top: 1px;
    padding: 0 10px;
}

.year-selector div.value .title {
    font-size: 1rem;
    margin: 0;
}

.font-size-1point2-rem {
    font-size: 1.2rem !important;
}

.no-wrap-day-round {
    word-break: keep-all;
    overflow-x: hidden;
}

.font-size-1point1-rem {
    font-size: 1.1rem !important;
}

.font-size-1point5-rem {
    font-size: 1.5rem !important;
}

.year-selector i.material-icons:hover,
div.add-icon:hover {
    background: #e6e6e6;
    border-radius: 50%;
}

ul.tabs li.indicator {
    height: 4px;
    background-color: #dd000b;
}

ul.tabs li.tab {
    font-weight: 600;
}

div.add-icon {
    display: inline-block;
    cursor: pointer;
    line-height: 1;
    padding: 10px 0;
}

div.add-budget-icon {
    display: inline-table;
    cursor: pointer;
    line-height: 1;
    bottom: 58px;
    right: 25px;
    position: absolute;
}

table.table-category-selector td {
    padding: 0;
}

div.billing-tabs {
    position: relative;
}

div.billing-tabs ul {
    height: 95%;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
}

.fix-height-tab>div>.collection {
    /*height: calc(100vh - 228px)*/
}

.fix-height-tab .collection .collection-item table tbody tr td {
    position: relative;
}

.fix-height-tab .collection .collection-item table tbody tr td .bottom-check {
    position: absolute;
}

.monthly-budget {
    width: 100%;
    float: left;
}

.monthly-budget li {
    width: 100%;
    float: left;
}

.monthly-budget li ul {
    width: 100%;
    float: left;
}

ul.table-dicsss li p {
    margin: 0;
    padding: 0;
}

ul.table-dicsss li p:last-child {
    color: rgb(165, 159, 169);
    padding-top: 14px;
}

.date-label {
    color: #000;
    padding-bottom: 6px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 310px;
    min-width: 310px;
    overflow: hidden;
    text-transform: capitalize;
}

.table-dicsss {
    position: relative;
}

.table-dicsss:after {
    width: 86.7%;
    height: 2px;
    background: #25ea5a99;
    position: absolute;
    top: 34px;
    right: 0;
    content: "";
}

.col.m6 .input-field .prefix~label {
    margin-left: 2rem;
}

.static-text .input-field .prefix {
    left: 0;
    font-size: 22px;
}

.static-text .input-field h5 {
    padding-left: 17px;
    font-size: 20px;
}

.modal .modal-content {
    position: relative
}

.modal {
    width: 40%;
}

.modal-footer-sec {
    width: 100%;
    float: left;
    border-top: solid 1px #ccc;
    position: absolute;
    bottom: -45px;
    left: 0;
    padding-left: 30px;
}

.static-text .left-value h5 {
    color: #00ab00;
}

.static-text .left-value.danger h5 {
    color: #f00;
}

.static-text h6 {
    color: #bebebe;
}

li.collection-item table .border-bottom td {
    border-bottom: solid 5px #bae29b;
}

li.collection-item table .border-bottom.danger td {
    border-bottom: solid 5px #f00;
}

li.collection-item table .border-bottom td:first-child {
    border-bottom: 0;
}

.static-text h3 {
    padding: 0;
    margin: 0;
    font-size: 2.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.static-text h5 {
    margin: 0;
}

.input-date input[type="month"]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-field input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.attach-Photo label {
    margin: 0 !important;
    padding: 0;
    display: flex;
    position: absolute;
    left: 0 !important;
    top: -13px;
}

label {
    word-break: normal !important;
}

.attach-Photo label i {
    margin-right: 5px;
}

.attach-Photo input {
    margin-left: 0 !important;
    opacity: 0;

}

.attach-Photo button {
    margin-top: 10px;
    width: 136px;
    line-height: 35px;
    border-radius: 5px;
}

.images-up img {
    width: 40%;
}

.input-expense-date input[type="date"]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-expense-date input[type="time"]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add-edit-expense .row .input-field {
    width: 100%;
}

.input-field.col.m6.s6.attach-Photo {
    width: 50%;
}

.input-field.col.m6.s6.images-up {
    width: 50%;
}

.input-field.attach-Photo label:not(.label-icon).active {
    transform: translateY(16px) scale(0.8);
}

.input-expense-date input[type="date"] {
    position: relative;
    color: #000;
}

.input-expense-date input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    -webkit-appearance: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.expense-image {
    position: relative;
    min-height: 51px;
}

.expense-image .loader-box {
    position: absolute;
    left: 0;
    top: 0;
}

.sweet-alert {
    color: #fff !important;
}

.sweet-alert-container {
    position: fixed !important;
    left: 23px !important;
    bottom: 60px !important;
    max-width: 100% !important;
}


#modal1 table.table-category-selector tr td [type="radio"]:checked+span,
#modal1 table.table-category-selector tr td [type="radio"]:not(:checked)+span {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    float: left;
    width: 18px;
    height: 18px;
    margin-top: 2px;
}

#modal1 table.table-category-selector tr td [type="radio"]:checked+span:before,
#modal1 table.table-category-selector tr td [type="radio"]:not(:checked)+span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 100%;
    background: #fff;
}

#modal1 table.table-category-selector tr td [type="radio"]:checked+span:after,
#modal1 table.table-category-selector tr td [type="radio"]:not(:checked)+span:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #ccc;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

#modal1 table.table-category-selector tr td [type="radio"]:not(:checked)+span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

#modal1 table.table-category-selector tr td [type="radio"]:checked+span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.input-income-date {
    position: relative;
    width: 30%;
    display: inline-block;
}

.input-income-date input[type="date"]::-webkit-calendar-picker-indicator,
.input-income-date input[type="date"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.view-image {
    text-align: center;
    overflow: auto;
    height: 320px;
}

.bill-icon {
    color: #2196f3;
    font-size: 40px !important;
    //transform: scale(1.5);
}

.payment-icon {
    color: #2196f3;
}

.wave-btn:hover {
    background-color: transparent;
}

div.add-icon:hover {
    background: #2196f30f;
    border-radius: 11%;
    padding: 10px 0;


}

.payment-btn:focus {
    background: transparent;
}

.input-date input[type="month"]::-webkit-calendar-picker-indicator {
    display: block;
    -webkit-appearance: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.active-record {
    background: #bbdefb42 !important;
}

.image-relative {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}


.table-align td,
.table-align th {
    vertical-align: top;
}

.table-align .leftMargin10 {
    margin: 4px 10px 0 0;
}

.table-align tr td.categories .text-break {
    float: left;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul.collection.fix-height-table li.collection-item:last-child {
    margin-bottom: 0 !important;
}

ul.collection.fix-height-collection li.collection-item:last-child {
    margin-bottom: 0 !important;
}


.input-field.col.m6.s6.attach-Photo label i {
    color: #000;
}

.input-field.col.m6.s6.attach-Photo label {
    color: #000;
}

.saving-report-label {
    margin-bottom: 0;
}

.saving-report-label .col>div {
    border-right: 1px solid;
    margin: 0 20%;
    float: left;
}

.saving-report-label .col:last-child div {
    border: none;
}

.saving-report-label b {
    width: 100%;
    float: left;
}

.saving-report-label p {
    position: relative;
    float: left;
    width: auto;
    padding-right: 16px;
}

.saving-report-label p:after {
    position: absolute;
    right: 0;
    top: 6px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    content: "";
}

.saving-report-label .saving-circle p:after {
    background: #3785bc;
}

.saving-report-label .expenses-circle p:after {
    background: #c04d36;
}

.saving-report-label .income-circle p:after {
    background: #87af6a;
}

.saving-circle span,
.expenses-circle span,
.income-circle span {
    color: #000;
}

.trend-legend p {
    text-align: left;
    padding-left: 20px;
    font-size: large;
}

.trend-legend p i {
    width: 14px;
    height: 14px;
    background: #c04d36;
    margin: 0 7px;
}

.radio-input-button label {
    position: relative;
    margin-right: 13px;
    float: left;
}

.radio-input-button label input[type="radio"]:not(:checked),
.radio-input-button label input[type="radio"]:checked {
    position: relative;
    opacity: 1;
    pointer-events: all;
}

.radio-input-button label input[type="radio"]:checked {
    position: relative;
    opacity: 1;

}

.radio-input-button label input {
    float: left;
    top: 5px;
}

.radio-input-button label span {
    color: #000;
    font-size: larger;
}

.overdue span {
    color: #c04d36 !important;
}

.saving span {
    color: #87af6a !important;
}

.saving-legend {
    text-align: center;
}

.saving-legend span .box {
    text-align: left;
    font-size: large;
}

.saving-legend span .box {
    width: 14px;
    height: 14px;
    margin: -3px 7px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
}

.input-block {
    display: flex;
    align-items: center;
}

.input-block .input-box {
    width: 100%;
}

.input-block .input-box select {
    border: 0;
    border-bottom: 1px solid #9e9e9e;
    width: 100%;
}

.input-block .input-box label {
    margin-left: 14px;
}

.content-area {
    overflow-y: auto;
    width: 104%;
    padding-right: 20px;
    overflow-x: hidden;
}

.Details-section,
.expenses-details-section,
.income-section,
.budget-section {
    padding-left: 10px;
}

.Details-section .innerPage table,
.income-section table {
    margin-top: 20px;
    margin-left: 20px;
    width: 90%;
}

span {
    word-break: normal;
}

.Details-section ul.tabs li.indicator {
    min-width: 100px;
}

ul.tabs li.indicator {
    min-width: 100px;
}

table.budget-no-padding.budget-monthly-category tr td img {
    height: 50px
}


.version-footer {
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 130px;
}

.version-footer p {
    margin: 0;
}

.total-price {
    position: relative;
    padding-top: 16px !important;
    text-align: right;
    padding-right: 14px !important;
}

.month-selector {
    background: rgb(246, 247, 248);
    font-weight: bold;
    padding-bottom: 7px;
}

.img-preview .swal2-popup {
    padding: 4px;
}

.img-preview .swal2-content {
    display: block;
    overflow: scroll;
    height: 420px;
}

.img-preview .swal2-close {
    background: none;
    right: 2%;
}

.img-preview .swal2-popup.swal2-modal.swal2-show {
    width: 70%;
}

#siteseal {
    position: fixed;
    width: 170px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    direction: rtl;
    bottom: 13px;
    left: 9%;
}

#siteseal img {
    height: 32px !important;
    width: auto !important;
}

.recaptcha div {
    margin: 0 auto;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-0 {
    margin: 0;
}

.item-center {
    text-align: center
}

.word-break {
    word-break: normal;
    text-align: center;
}

.nowrap {
    white-space: normal;
}

.content-ltr {
    direction: rtl;
}

.profile-image {
    width: 214px;
    height: 214px;
    border: solid 8px #e8f3fd;
    display: flex;
    margin: auto;
    background: #e8e3e3;
    object-fit: cover;
    box-sizing: content-box;
}

.choose-file-input {
    margin: 10px 0;
    width: 100%;
    position: relative;
    display: flex;
}

.choose-file-input input {
    width: 100%;
    background: #000;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.choose-file-input input:focus {
    outline: inherit;
}

.profile-picture {
    float: left;
    position: relative;
    margin: auto;
    display: flex;
    width: auto;
    align-items: center;
    align-content: baseline;
}

.profile-picture i {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 25px;
    height: 25px;
    background: #1976d2;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 25px;
    border-radius: 62%;
    transform: translate(40%, 40%);
}

.profile-picture i.material-icons.clear {
    right: 0;
    top: -20px;
    left: inherit;
    cursor: pointer;
}

.choose-file-input button {
    width: auto;
    margin: 10px auto;
    display: flex;
}

.card-content.change-password .row .col {
    padding: 0 .75rem;
}

.change-password .input-block {
    width: 70%
}

.change-password .input-field.col {
    width: 70%;
}

.row.flex-container {
    display: flex;
    align-items: center;

}

.flex-box {
    display: flex;
    align-items: center;
}



.recharts-wrapper {
    width: 100% !important;
    height: initial !important;
}

.recharts-wrapper svg:not(:root) {
    overflow: visible;
    width: 100%;
    height: 100%;
}

.row.flex-container .col {
    padding: 0 .75rem;
}

.black-colored-checkbox>.col>[type="checkbox"]+label {
    color: black;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.reports-section-page .no-padding .card-content {
    padding: 0;
}

.reports-section-page .saving-report-label .col>div {
    border-right: 1px solid;
    margin: 0;
    float: left;
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
}

.reports-section-page .saving-report-label .col>.saving-circle.saving {
    border-right: 0;
}

.Trends-svg {
    position: relative;
}

.Trends-svg .recharts-wrapper {
    width: 800px;
    height: 500px;
}

.flex-container {
    display: -webkit-flex;
    display: flex;
    margin-top: 15px;
}

.flex-container .column {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.input-min-height .input-field {
    width: 100%;
}

.input-min-height .input-field input {
    min-height: 50px;
}

.button-upload {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 13px;
}

.row.flex-container {
    margin-bottom: 0;
}

.sign-in-section .row .col {
    padding: 0 .75rem;
}

.header-avatar-div {
    float: left;
}

.header-avatar-div>img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-top: 8px;
    margin-right: 2px;
}

.header-name-arrow {
    display: flex;
    align-items: center;
}

.pointer-class {
    cursor: pointer;
}

.column.input-min-height .input-block .input-box {
    width: 100%;
    min-height: 86px;
}

#siteseal {
    display: none !important;
}

.certificates-footer {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.certificates-footer img {
    width: 110px;
    float: left;
}

.certificates-footer img:first-child {
    width: 44px;
}

.sign-in-section {
    display: flex;
    justify-content: space-around;
    height: calc(100vh - 100px);
}

.sign-in-section .card-content {
    margin-top: 20px;
}

.sign-in-section .input-field input {
    padding-left: 12px;
    padding-right: 12px;
    border: solid 1px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-color: rgb(184, 196, 194);
    border-radius: 3px;
}

.sign-in-section .input-field input:focus {
    box-shadow: 0 0 0 2px rgba(38, 166, 154, 0.73) !important;
    border: solid 1px rgba(38, 166, 154, 0.73);

}

.sign-in-section .input-field label {
    padding-left: 12px;
    color: gray;
}

.sign-in-section .card.darken-1 {
    width: 32%;
    margin: auto;
}

.sign-in-section .input-field label.active {
    top: -7px;
    transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1), color 400ms cubic-bezier(0.25, 0.8, 0.25, 1), width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.border-remove {
    border: 0;
}

.padding-remove .card-content {
    padding: 0;
}


.padding-remove .card-content .card-title {
    margin: 0;
    padding: 24px 0 0 24px;
}

.back-button {
    color: #000;
    margin-left: 10px;
    margin-top: 5px;
    width: auto;
    float: left;
    padding: 1px 7px;
    border-radius: 7px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    display: none;
    cursor: pointer;
    font-weight: normal;
}

.back-button i {
    padding-right: 5px;
}

.card.darken-1.template-section form div p {
    font-size: 15px;
    float: left;
    width: 100%;
    margin-top: 10px;
}

.template-section .radio-input-button label span {
    color: #000;
    font-size: larger;
    margin-left: 5px;
}

.email-id-title {
    padding-left: 7px;
}

.template-page ul#mobile-demo {
    display: none;
}

.template-page a.left.hide-on-large-only {
    display: none;
}

.header-user-icons {
    float: left;
    display: flex;
    align-items: center;
    padding: 0 16px;
    width: 100%;
}

.header-user-icons img {
    margin-top: 0;
}

.profile-save-button {
    float: right;
    margin-bottom: 17px;
    width: 100%;
    text-align: right;
}

.column.input-min-height.Password-section-change .col {
    padding: 0;
}

.input-field.col.m10.s12.last-input .col.input-field.s12 {
    padding: 0;
}

.input-field.col.m10.s12.last-input label {
    padding: 0;
}

.row.margin-0.Already-div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    flex-direction: column;
}

.text-align-center {
    text-align: center;
}

.row.margin-0.Already-div .text-align-center button {
    width: 60%;
}

.row.margin-0.Already-div .text-align-center a {
    padding-left: 5px;
}

.by-clicking {
    text-align: center;
}

.top-Spends-left .col {
    padding: 0 .75rem;
}


.block-2 li {
    width: 49%;
    float: left;
    margin: 0.5% !important;
}


.tabs {
    height: 60px;
}

.tabs li {
    height: 60px;
    line-height: 60px;
}

.user-options ul li a {
    color: rgba(0, 0, 0, 0.87);
}

.user-options ul li i {
    color: rgba(0, 0, 0, 0.54);
}

.react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: 0 !important;
    border-radius: initial !important;
}

.react-tel-input .country-list {
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 0 0 10px -1px;
    -webkit-box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: white;
    width: 300px;
    max-height: 270px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
    min-height: 186px;
}

.card-content.change-password .btn {
    margin-top: 25px;
}


.user-options .btn:hover {
    background-color: #bbdefb;
    color: #000;
}

.phone-input {
    box-sizing: border-box !important;
}

.bills-details-table tr td:nth-child(1) {
    width: 90px;
}

div.billing-tabs ul li,
.tab-mobile-fixed ul li {
    border-bottom: solid 4px #ffffff;
}

div.billing-tabs ul li.active-tab,
.tab-mobile-fixed ul li.active-tab {
    border-bottom: solid 4px #dd000b;
}

.no-padding-mobile ul li .col {
    padding: 0 !important;
}

.recharts-responsive-container.valign-wrapper {
    height: initial !important;
}

.recharts-responsive-container.valign-wrapper svg {
    width: 90% !important;
}

.Top-Spends-recharts .recharts-responsive-container.valign-wrapper svg {
    width: 90% !important;
}

.Top-Spends-recharts .recharts-responsive-container.valign-wrapper {
    text-align: center;
}

.loading-all-page {
    width: 100%;
    height: calc(100vh - 119px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading-all-page h1 {
    font-size: 22px;
}

.tab-mobile-fixed {
    width: 100%;
}

.font-size-1rem {
    font-size: 1rem !important;
}

.detail-tab li {
    border-bottom: solid 4px #dd000b;
    height: inherit !important;
}

.detail-tab li.indicator {
    display: none;
}

.Trends-svg .recharts-wrapper svg {
    width: auto;
    height: auto;
}

.min-height346 {
    min-height: 346px;
}

.min-height346 .row.flex-container {
    min-height: 275px;
}

.user-options ul.dropdown-content.active {
    width: auto !important;
    min-width: 250px;
}

.submit-loader-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.template-row-margining {
    margin: 5px 0 35px 0 !important;
}

.question-body {
    margin-bottom: 20px !important;
    font-size: 16px !important;
}

.template-title {
    display: flex;
    justify-content: center;
    font-size: 30px !important;
}

.template-subtitle {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: normal !important;
    margin-bottom: 40px;
}

.reduced-space-label-symbol+label {
    padding-left: 25px !important;
    margin-right: 25px !important;
    color: #36454f !important;
}

.mandatory-asterisk p:after {
    color: #e32;
    content: ' *';
    font-weight: 900;
    display: inline;
}

.template-question {
    font-size: 18px !important;
}

.template-row-margining .radio-input-button [type="radio"]:checked+label:after,
[type="radio"].with-gap:checked+label:after {
    background-color: #1976d2;
}

.template-row-margining .radio-input-button [type="radio"]:checked+label:after,
[type="radio"].with-gap:checked+label:before,
[type="radio"].with-gap:checked+label:after {
    border: 2px solid #1976d2;
}

.template-row-margining .radio-input-button [type="checkbox"]:checked+label:before {
    border-right: 2px solid #1976d2;
    border-bottom: 2px solid #1976d2;
}

.template-select {
    display: block;
    width: 33%
}

@media only screen and (max-width : 992px) {
    .template-select {
        width: auto
    }
}

.activate-page .card-title {
    word-break: normal !important;
}

.not-found span {
    word-break: normal !important;
}

.reset-password-section h3 {
    font-size: 19px;
    padding: 0;
    margin: 0;
}

.reset-password-section .row .col {
    padding: 0 .75rem;
}

.reset-password-section .input-field.col.l11.m11.s12 {
    margin-bottom: 15px;
}

.reset-password-section input {
    padding-left: 5px;
}

.green-color span {
    word-break: normal !important;
}

.long-symbole {
    font-size: 1.4rem !important;
    padding-top: 15px;
}

.disclaimer-content {
    padding: 5px;
    display: flex;
    justify-content: center;
    word-break: normal;
}

.disclaimer-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}

.hide-disclaimer-div {
    display: none !important;
}

.close-disclaimer {
    margin-left: 10px;
    cursor: pointer;
}

.disclaimer-content p {
    display: flex;
    align-items: center;
}

.disclaimer-content a {
    margin-right: 5px;
    margin-left: 5px;
}

.wrap-it {
    display: contents;
}

.disclaimer-box .card .card-content {
    padding: 10px !important;
}

.disclaimer-box .card {
    margin-bottom: 0 !important;
    background-color: #fff3c2;
}

.input-field .prefix {
    width: 4rem;
}

.input-field .prefix~input,
.input-field .prefix~textarea,
.input-field .prefix~label,
.input-field .prefix~.validate~label,
.input-field .prefix~.autocomplete-content {
    margin-left: 4rem;
    width: calc(100% - 4rem);
}

.message-icon {
    font-size: 24px !important;
}

.display-flex {
    display: flex;
}

.align-horizontal {
    display: flex;
    align-items: center;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.date-flex {
    display: flex;
    align-items: center;
    margin-bottom: 30px !important;
    flex-direction: column;
    margin-top: -15px;
}

.date-flex .input-field.col.m6.s12.input-expense-date {
    margin-top: 0;
}

.date-flex .input-field.col.m6.s12.input-expense-date label {
    font-size: 0.8rem;
}

.date-flex label {
    position: relative;
}

.date-flex span#date-text {
    margin-left: 0 !important;
    float: right !important;
    width: 100% !important;
    margin-top: 8px;
}

h5.zero-margin {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #989898;
    font-size: 11px;
    text-align: center;
    line-height: 21px;
    color: #fff;
    font-weight: 500;
}

h5.zero-margin-detail {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #989898;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-weight: 500;

}

.margin1-1 {
    margin-left: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-50 {
    margin-bottom: 50px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-20 {
    margin-top: 20px
}

.margin-top-30 {
    margin-top: 30px
}

.margin-top-35 {
    margin-top: 35px
}

.auto-margin {
    margin: auto;
}

.point-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 4px;
}

.text-transform-none {
    text-transform: none;
}

table.budget-no-padding.budget-monthly-category tr td:nth-child(2) {
    max-width: 150px;
    display: inline-block;
}

table.budget-no-padding.budget-monthly-category tr td:nth-child(3) {
    max-width: inherit;
}

.income-table-monthly tr td:nth-child(2) {
    max-width: 270px;
}

.UpcomingTable tr td:nth-child(2) {
    max-width: 270px;
}


.bills-monthly-category tr td:nth-child(3),
.income-table-monthly tr td:nth-child(3) {
    text-align: right;
    float: right;
}


.bills-monthly-category tr td .date-label {
    max-width: inherit;
    min-width: inherit;
}

table.budget-no-padding.budget-monthly tr td span {
    word-break: break-all !important;
}

.balance-label {
    font-weight: normal;
    margin: 0;
    margin-top: 4px;
}

.balance-label p {
    margin: 0;
}

.balance-label-bill {
    font-weight: normal;
    margin: 0;
    margin-top: 4px;
}

.blue-text {
    color: #2196f3;
}

.padding-l-5 {
    padding-left: 5px;
}

.height-30 {
    height: 30px;
    padding: 3px;
}

.min-width-100 {
    min-width: 100px;
}

.notice-ui {
    background-color: rgb(240, 248, 255);
    padding-top: 10px;
    display: inline-flex;
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;
}

.notice-ui .text-nowrap {
    white-space: nowrap;
}

.notice-ui img {
    width: 70%;
}

.relative-pos {
    position: relative;
}

.timezone-notice {
    background-color: rgb(240, 248, 255);
    padding-top: 10px !important;
    margin-top: 2px;
    padding-bottom: 5px !important;
    padding-right: 10px !important;
}

.height-20 {
    height: 20px !important;
}

.height-54 {
    height: 54px;
}

.yearSelector img {
    padding-left: 0 !important;
    margin-right: 0;
}

img.zero-margin.height-20 {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.accountDetailCategories .text-break.grey-text {
    width: 250px !important;
}

.accountDetailCategories .text-break {
    width: 250px !important;
}

.break-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.backdrop-div {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(207, 209, 207, 0.35);
    z-index: 9999;
    width: 100%;
    height: 100%;
}

.import-trx-div {
    border: 2px solid #1976D2;
    padding: 10px;
    border-radius: 5px;
    background: #F0F8FF;
    color: #000000;
}

.import-steps {
    margin-top: 5px;
    font-size: 14px;
    color: #000000;
}

.font-size-16-bold {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.color-black {
    color: #000000;
}

.margin-top-20 {
    margin-top: 20px
}

.padding-8 {
    padding: 8px;
}

.google-btn {
    width: 80%;
    /* Button width at 80% */
    display: flex;
    /* Flexbox for centering content */
    align-items: center;
    /* Vertical centering */
    justify-content: center;
    /* Horizontal centering */
    padding: 10px;
    /* Padding for better spacing */
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* Thin border with slight transparency */
    border-radius: 5px;
    /* Rounded corners */
    /* box-shadow: 0px 2px 4px rgba(233, 244, 254, 0.8); */
    /* Subtle shadow */
    background-color: white;
    /* Background color */
    cursor: pointer;
    /* Pointer cursor for buttons */
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    /* Smooth effects on hover */
    margin: 0 auto;
    /* Center the button horizontally */
}

.google-btn:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Enhanced shadow on hover */
    transform: scale(1.02);
    /* Slight scaling effect */
}

.google-btn img {
    margin-left: 8px;
    /* Spacing between text and logo */
    vertical-align: middle;
    /* Align logo with text */
}