body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
  word-break: normal;
}
i.disable {
  opacity: 0.6 !important;
  color: #ccc;
  pointer-events: none;
  opacity: 0.4;
}
.select-input {
  border: 1px solid #efefef !important;
  width: 280px !important;
  height: 38px !important;
  display: block;
}

.tb-dropwond-box {
  width: 100%;
  border: 1px solid rgb(242, 242, 242);
  line-height: 35px;
  background: #fff;
  padding-left: 5px;
}
.tb-dropwond-box.disable{
  opacity: 0.6;
}
.tb-dropwond-box .material-icons {
  transform: rotate(90deg);
  float: right;
  line-height: 36px;
}
.tb-overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0); /* Black background with opacity */
  z-index: 10000; /* Specify a stack order in case you're using a different order for other elements */
}
ul.tb-bs-dropdown-menu {
  list-style: none;
  position: absolute;
  white-space: nowrap;
  background: #fff;
  padding: 5px 0;
  background-color: #fff;
  z-index: 100;
  min-width: 180px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
}

ul.tb-bs-dropdown-menu a {
  display: block;
  min-width: 60px;
  min-height: 30px;
  color: #48576a;
  text-decoration: none;
  cursor: pointer;
  padding: 3px;
  background-color: #fff;
  float: none;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
}
ul.tb-bs-dropdown-menu > li:hover > a {
  background-color: #d1dbe5;
}
ul.tb-bs-dropdown-menu > li {
  list-style: none;
  min-width: 100px;
}
ul.tb-bs-dropdown-menu > li > ul {
  position: absolute;
  display: none;
  list-style: none;
  width: 100%;
  margin-left: calc(100% + 0px);
  margin-top: -35px;
  padding: 5px 0;
  background-color: #fff;
  z-index: 100;
  min-width: 100px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
}
ul.tb-bs-dropdown-menu > li:hover > ul > li:hover > a {
  background-color: #d1dbe5;
}

ul.tb-bs-dropdown-menu > li:hover > ul {
  display: block;
}

ul.tb-bs-dropdown-menu .material-icons {
  float: right;
  line-height: 36px;
  margin-top: -36px;
}

.timelableinaccoutn>i{
position: relative;
top: 2px
}
.tolltipconent {
  list-style: disc !important;
  list-style-position: inside !important;

}
.tolltipconent>li>i {
  position: relative;
  top: 0px;
 
  font-size: 10px;
}
.tolltipconent li {
  text-align: left;
  border: none !important;
}
.pendinglable {
  display: inline-block;
  margin-right: -20px;
  font-size: 10px;
  padding: 0px 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #ffffcc;
}

.grow {
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  height: 0;
  overflow: hidden;
}

.grow .text {
  color: #6a6a6a;
  margin: 5px;
}
.grow .text .listd {
    display: list-item;          /* This has to be "list-item"                                               */
    list-style-type: disc;       /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
    list-style-position: inside;
}

.slidediv-root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.slidediv-root>div {
  width: 300% !important;
}

.slidediv-root>div>div {
  width: calc(100% / 3) !important;
  float: left;
}
.next {
  margin-left: -100%;
  transition: all 1s ease;;
}
.c-margin-0 {
  margin: 0 !important;
}
.hide-show-pass i {
  margin-top: -56px;
    margin-right: 3px;
    cursor: pointer;
    z-index: 121212;
    left: calc(100% - 8.34% - 18px);
    top: 64px;
    position: absolute;
}