@media (max-width: 467px) {
    div.billing-tabs ul li {
        font-size: 12px;
        padding-left: 8px;
    }

    .row.mb-0.mobile-font div {
        font-size: 12px;
    }

    table.budget-no-padding.budget-monthly tr td:nth-child(1) {
        width: 16%;
    }

    .reports-section ul li {
        padding: 23px 15px 10px 8px;
        font-size: 12px;
    }

    .reports-section .row .col {
        padding: 0 .75rem;
    }

    .tab-filter-mobile i {
        font-size: 20px;
    }

}

@media (max-width: 600px) {
    .reports-section-page .saving-report-label .col > div {
        border-right: 0;
        padding: 4px;
    }
}

@media only screen and (max-width: 768px) {
    /*.vertical-center .card {
        box-shadow: inherit;
    }*/
    .vertical-center h3{
        font-size: 22px;
    }
    .vertical-center h5{
        font-size: 15px;
    }
    .activate-page .card-content.center-align img {
        width: 20%;
    }

    .activate-page span.card-title h3 {
        margin: 0;
        font-size: 18px;
    }

    .activate-page h5 {
        margin: 0;
        font-size: 16px;
    }

    .activate-page a h5 {
        font-size: 14px;
    }

    .vertical-center.activate-page {
        display: flex;
        align-items: center;
        height: 90vh;
        padding: 15px;
    }

    .vertical-center.activate-page .card.darken-1 {
        box-shadow: inherit;
    }
    .vertical-center.activate-page .card.darken-2 {
        box-shadow: inherit;
    }

    .expenses-details-section.mobile-no-view.detailView table tr td img {
        height: 40px !important;
    }

    .expenses-details-section.mobile-no-view.detailView table .button-div td button {
        display: flex;
        padding: 0 1rem;
    }

    .block-2 li {
        width: 100%;
    }

    .modal {
        width: 94%;
    }

    .email-id-title {
        display: none;
    }

    a.left.hide-on-large-only {
        margin-left: 11px;
    }

    .user-options ul.dropdown-content {
        width: 100% !important;
        left: 0 !important;
        top: 55px !important;
    }

    nav .brand-logo {
        margin-left: 43px;
    }

    .select-wrapper.initialized .dropdown-content {
        max-height: 320px;
    }

    .card .card-title {
        font-size: 18px;
    }

    .hoverable.card .card-content h3.center.grey-text {
        font-size: 17px;
    }

    div.billing-tabs ul {
        overflow: auto;
    }

    .date-label {
        font-size: 15px;
        max-width: initial;
        min-width: initial;
    }

    .billing-tabs .css-glamorous-div {
        left: 0 !important;
    }

    .mobile-view {
        display: none;
    }

    .Details-section {
        padding-left: 0;
    }

    .Details-section .innerPage table {
        width: 100%;
        margin-left: 0;
    }

    .Details-section .add-icon i {
        margin-left: 0;
    }

    .Details-section .add-icon button {
        padding: 0 1rem;
    }

    .Details-section .innerPage table .leftMargin10 {
        margin: 0;
        margin-left: 14px;
    }

    .Details-section .innerPage table tr td:nth-child(1) i {
        padding-left: 14px;
    }

    .expenses-details-section {
        padding-left: 0;
    }

    .day-round {
        background: orange;
        line-height: 40px;
        text-align: center;
        border-radius: 100%;
        font-size: 15px;
        width: 40px;
        margin-right: 15px;
    }

    ul.collection.fix-height-tab.income-section-left li table tr td img {
        height: 35px !important;
    }

    .income-section tr td i {
        margin-left: 0;
    }

    .input-field.col.m6.s6.attach-Photo {
        width: 100%;
    }

    .Details-section .innerPage tr td {
        font-size: 2rem !important;
    }

    .Details-section .innerPage tr td img {
        width: auto;
        height: 30px !important;
    }

    .Delete-button {
        display: flex;
    }

    table.budget-no-padding.budget-monthly-category tr td img {
        height: 30px;
    }

    table.budget-no-padding.budget-monthly-category tr td:nth-child(2) {
        width: 55% !important;
    }

    table.budget-no-padding.budget-monthly-category tr td:nth-child(3) {
        width: 35% !important;

    }

    .choose-file-input input:after {
        width: 92px;
    }

    .flex-container {
        flex-direction: column;
    }

    .choose-file-input input:after {
        width: 92px;
    }

    .card .card-content {
        padding: 0;
    }

    .card .card-content .card-title {
        padding: 7px 9px;
    }

    .Income-expense-padding .card-content {
        padding: 24px;
    }

    .Statement-padding .card-content {
        padding: 15px;
    }

    .vertical-center .card-content.change-password {
        padding: 1px;
    }

    .forgot-password-section .row .col {
        padding: 0 .75rem;
    }

    .forgot-password-section h3 {
        font-size: 18px;
        margin: 0;
        padding: 0;
        line-height: initial;
    }

    .forgot-password-section h5 {
        font-size: 16px;
        margin: 0;
        padding: 0;
        line-height: initial;
    }

    div.add-budget-icon {
        bottom: 30px;
        position: fixed;
        z-index: 999;
    }

    .sign-in-section .card.darken-1 {
        width: 92%;
        margin: auto;
    }

    .sign-in-section .card.darken-2 {
        width: 92%;
        margin: auto;
    }

    a.forgot-password-font {
        font-size: 13px;
    }

    .settings-section .card-content {
        padding: 24px;
    }

    .swal2-container.swal2-bottom-start.sweet-alert-container.swal2-shown {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0 !important;
    }

    .recharts-legend-wrapper {
        position: relative !important;
    }

    .template-section-page {
        padding: 0 .75rem;
    }

    .template-section-page .card.darken-1.template-section {
        padding: 24px;
    }
    .template-section-page .card.darken-2.template-section {
        padding: 24px;
    }

    .row.margin-0.Already-div {
        display: inherit;
    }

    .by-clicking {
        padding: 15px;
    }

    .bill-grey-month {
        padding: 8px;
        padding-left: 15px;
    }

    table.no-padding.table-align tr td {
        min-width: 100px;
        max-width: 100px;
    }

    table.no-padding.table-align tr td:nth-child(1) {
        min-width: 0;
        max-width: 35px;
    }

    table.no-padding.income-table-monthly tr td:nth-child(1) {
        width: 50px;
    }

    .col.m12.static-text.border-right {
        width: 100%;
    }

    span.percent {
        display: none;
    }

    .top-Spends-left .col {
        padding: 0;
    }

    .budget-monthly tr td span {
        word-break: normal;
    }

    .budget-monthly tr td:nth-child(2) {
        width: 10px;
    }

    .bills-details-table tr td:nth-child(1) {
        width: 60px;
    }

    .bills-details-table tr td i {
        margin-left: 14px;
        width: 30px;
        height: 30px;
        font-size: 30px !important;
        padding: 0;
        text-align: center;
        padding-left: 0 !important;
        line-height: 30px;
    }
    .bills-details-table tr td h5 {
        text-align: center;
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin: auto;
    }

    .bills-details-table tr td button {
        line-height: 30px;
    }

    .bills-details-table tr td button i {
        font-size: 1.3rem !important;
    }

    .no-padding-mobile {
        padding: 0 !important;
    }

    .mobile-right span {
        float: right;
    }

    .no-padding-mobile {
        padding: 0;
    }

    .no-padding-mobile ul li .col {
        padding: 0 !important;
    }

    /*table mobile fixed*/
    .tab-mobile-fixed {
        position: fixed;
        width: 100%;
        border: 1px solid #f1eaea;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }

    .tab-mobile-fixed div ul {
        width: 100%;
        position: fixed;
    }

    .fix-height-tab {
        margin-top: 58px;
    }
    .bills-tab-height-top{
        margin-top: 70px !important; 
    }

    .bill-tab-fixed {
        margin-top: 0px;
    }

    .row.mb-0.month-selector {
        margin-top: 61px;
    }

    /*table mobile fixed*/
    .overlap-tab-filter.tab-filter-mobile {
        right: 53px;
    }

    .m-t-52 {
        margin-top: 52px;
    }

    ul.collection.fix-height-tab.height-expenses {
        margin-top: 0;
    }

    .min-height346 {
        min-height: inherit;
    }

    .min-height346 .row.flex-container .col.s12.m12.l5 {
        min-height: 275px;
    }

    .collection .collection-item:last-child {
        margin-bottom: 120px;
    }

    .fix-height-tab {
        height: 100%;
    }

    ul.collection.fix-height-tab.height-expenses {
        height: 93%;
    }

    ul.collection.fix-height-tab.fix-height-table {
        height: calc(100vh - 114px);
    }

    .template-title h3 {
        font-size: 25px;
        margin: 0;
        margin-bottom: 10px;
    }

    .template-subtitle {
        font-size: 14px;
        margin-bottom: 0;
    }

    .template-question {
        font-size: 14px !important;
    }

    .template-row-margining {
        display: flex;
        flex-direction: column;
    }

    .template-row-margining label {
        height: inherit !important;
    }
    .signButton button{
        padding: 0 1.5rem;
    }
}

@media only screen and (max-width: 992px) {
    .card.darken-1.template-section form div p {
        font-size: 15px;
        margin-bottom: 8px;
        float: left;
        width: 100%;
    }
    .card.darken-2.template-section form div p {
        font-size: 15px;
        margin-bottom: 8px;
        float: left;
        width: 100%;
    }

    a.left.hide-on-large-only {
        margin-left: 11px;
        cursor: pointer;
    }

    .date-label {
        font-size: 15px;
    }

    .Details-section .innerPage tr td {
        font-size: 1rem !important;
    }

    .Details-section .innerPage tr td img {
        width: auto;
        height: 30px !important;
    }

    .Details-section .innerPage tr td div {
        margin-right: 10px;
        font-size: 16px;
    }

    ul.collection.fix-height-tab.income-section-left li table tr td img {
        height: 35px !important;
    }

    .income-section tr td img {
        height: 30px !important;
    }

    .static-text h3 {
        font-size: 18px;
        line-height: inherit;
    }

    .hoverable.card .card-content h3.center.grey-text {
        font-size: 17px;
    }

    .expenses-details-section ul.tabs li.indicator,
    .income-section ul.tabs li.indicator,
    .budget-section ul.tabs li.indicator,
    .Details-section ul.tabs li.indicator {
        width: 100%;
    }

    .row.flex-container {
        flex-direction: column;
        margin-bottom: 14px;
    }

    .mobile-no-view {
        display: none;
    }

    .detailView {
        position: fixed;
        top: 55px;
        left: 0;
        background: #fff;
        height: 100vh;
        width: 100%;
        z-index: 99;
        display: block;
    }

    .back-button {
        width: 100%;
        display: flex;
    }

    .back-button span {
        width: calc(100% - 37px);
    }

    .Details-section, .expenses-details-section, .income-section, .budget-section {
        padding-left: 0;
    }

    .mobile-line-height {
        display: none;
    }

    .sign-in-section .card.darken-1 {
        width: 90%;
        margin: auto;
        padding-bottom: 20px;
    }
    .sign-in-section .card.darken-2 {
        width: 90%;
        margin: auto;
        padding-bottom: 20px;
    }

    .change-password .input-block {
        width: 100%
    }

    .change-password .input-field.col {
        width: 100%;
    }

}

@media only screen and (min-width: 992px) and (max-width: 1169px) {
    .card.darken-1.template-section form div p {
        font-size: 15px;
        margin-bottom: 8px;
        float: left;
        width: 100%;
    }
    .card.darken-2.template-section form div p {
        font-size: 15px;
        margin-bottom: 8px;
        float: left;
        width: 100%;
    }

    .date-label {
        max-width: initial;
        min-width: initial;
        font-size: 14px;
    }

    div.billing-tabs ul li {
        padding: 25px 8px 9px 6px;
        font-size: 14px;
    }

    .Details-section .innerPage table tr td img {
        height: 29px !important
    }

    .Details-section .innerPage table tr td i {
        font-size: 2rem !important;
    }

    .Details-section .innerPage table tr td div {
        font-size: 14px !important;
    }

    .Details-section .innerPage table tr td button {
        padding: 0 1rem;
    }

    .Details-section .innerPage table tr td button i {
        margin-left: 3px;
        font-size: 1rem !important;
    }

    ul.collection.fix-height-tab.income-section-left li table tr td img {
        height: 45px !important;
    }

    .income-section tr td img {
        height: 33px !important;
    }

    .income-section tr td div {
        font-size: 18px !important;
    }

    .income-section tr td i {
        margin-left: 0;
    }

    .income-section tr td button {
        padding: 0 1rem;
    }

    .Details-section .innerPage tr td {
        font-size: 1rem !important;
    }

    .Details-section .innerPage tr td img {
        width: auto;
        height: 30px !important;
    }

    ul.collection.fix-height-tab.income-section-left li table tr td img {
        height: 35px !important;
    }

    table.budget-no-padding.budget-monthly tr td:nth-child(1) {
        width: 17% !important;
    }

    .static-text h3 {
        font-size: 18px;
        line-height: inherit;
    }

    table.budget-no-padding.budget-monthly-category tr td img {
        height: 30px;
    }

    table.budget-no-padding.budget-monthly-category tr td:nth-child(2) {
        width: 50%;
        font-size: 12px;
    }

    table.budget-no-padding.budget-monthly-category tr td:nth-child(3) {
        width: 50%;
    }

    table.budget-no-padding.budget-monthly-category tr td:last-child span {
        font-size: 12px;
    }

    .tablet-devices {
        width: 100% !important;
    }

    .sign-in-section .card.darken-1 {
        width: 100%;
        margin: auto;
    }
    .sign-in-section .card.darken-2 {
        width: 100%;
        margin: auto;
    }

    .block-2 li {
        width: 100%;
        float: left;
        margin: 0.5% !important;
    }
}

@media only screen and (min-width: 1170px) and (max-width: 1279px) {
    .date-label {
        font-size: 19px;
    }

    .Details-section .innerPage tr td img {
        width: auto;
        height: 40px !important;
    }

    .Details-section .innerPage tr td div {
        font-size: 20px;
    }

    ul.collection.fix-height-tab.income-section-left li table tr td img {
        height: 35px !important;
    }

    .income-section tr td img {
        height: 33px !important;
    }

    .Details-section .innerPage table tr td div {
        font-size: 14px !important;
    }

    .tablet-devices {
        width: 100% !important;
    }

    .sign-in-section .card.darken-1 {
        width: 100%;
        margin: auto;
    }
    .sign-in-section .card.darken-2 {
        width: 100%;
        margin: auto;
    }

    .block-2 li {
        width: 100%;
        float: left;
        margin: 0.5% !important;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1500px) {
    .tablet-devices .recharts-wrapper svg:not(:root) {
        width: 60%;
        margin: auto;
        display: flex;
    }

    .tablet-devices ul.collection.fix-height-collection .collection-item {
        padding: 6px 5px;
        /*font-size: 12px;*/
    }

    .sign-in-section .card.darken-1 {
        width: 40%
    }
    .sign-in-section .card.darken-2 {
        width: 44%
    }
}

select.PhoneInputCountrySelect {
    display: block !important;
}

@media only screen and (min-width: 990px) and (max-width: 1300px) {
    .Profile-mobile, .billing-tabs {
        width: 50% !important;
    }
}

.nowrap span {
    word-break: normal !important;
}

.phone-input {
    padding-left: 48px !important;
}

[type="radio"]:not(:checked) + label:before, [type="radio"]:not(:checked) + label:after {
    border: 1px solid #989898;
}

[type="checkbox"] + label:before, [type="checkbox"]:not(.filled-in) + label:after {
    border: 1px solid #989898;

}

.date-block {
    position: relative;
    display: flex;
    padding-left: 10.5% !important;
}

.date-input {
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 9;
    opacity: 0;
    width: 100% !important;
}

@media only screen and (max-width: 360px) {
    .width-100 {
        width: 100% !important;
        text-align: center;
    }
}
.red-text {
    color: #FF0000;
}
.zero-margin{
    margin: 0 !important;
}
.flex-box-recurring{
    display: flex;
    align-items: center;
    justify-content: center;
}
.amount-font-size{
    font-size: 24px;
}
.align-right {
    text-align: right;
}
.no-data-label {
    color: #000;
    padding-bottom: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 310px;
    overflow: hidden;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 300;
    margin-top: 50px;
}
.statement-radio label{
    border: 1px solid #000;
    position: relative;
    padding: 5px;
    border-radius: 3px;
    min-width: 135px;
    text-align: center;
    margin-right: 0;
    cursor: pointer;
}
.statement-radio label.select{
    background: #0091ea;
}
.statement-radio label input{
    opacity: 0 !important;
    width: 100%;
    height: 100%;
    position: absolute !important;
}
.statement-radio label span{
    color: gray !important;
    font-size: 1rem;
}
.statement-radio label.select span{
    color: #fff !important;
}
.radio-input-button {
    margin-bottom: 0 !important;
}
.margin-bottom30 {
    margin-bottom: 30px !important;
    margin-top: 0.5rem;
}
.accept-invite-logo {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: solid 5px #1976d238;
}
.multi-user-creator{
    padding-bottom: 8px;
}

@media only screen and (max-width: 768px) {

    .Details-section .innerPage .bills-details-table tr td img {
        margin-left: 14px !important;
        width: 30px !important;
    }
    .sign-in-section .card.darken-2 {
        width: 90%;
        margin: auto;
        padding-bottom: 20px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    }
    .margin-bottom-50.margin-top-20 button {
        margin-bottom: 10px;
    }

}
@media (min-width: 769px) and (max-width: 992px) {

    .Details-section .innerPage .bills-details-table tr td img {
        margin-left: 0 !important;
        width: 40px !important;
        height: 40px !important;
    }
    .sign-in-section .card.darken-2 {
        width: 90%;
        margin: auto;
        padding-bottom: 20px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    }
    .margin-bottom-50.margin-top-20 button {
        margin-bottom: 10px;
    }

}
@media (min-width: 993px) {
    .sign-in-section .card.darken-2 {
        max-width: 650px;
    }
    .pro-feature {
        margin-right: calc(100% - 84%) !important;
    }
}
.pro-feature {
    text-align: center;
}
.pro-feature img{
    width: 110px;
}
.pro-feature a{
    line-height: 36px;
}
.pro-feature .action{
    text-align: center;
}
.pro-feature .action h4{
    font-weight: bold;
}
.pro-feature .action a{
    margin-top: 30px;
}
.pro-feature .action a i{
    transform: rotate(-90deg);
}
.goto-pro-link {
    margin-top: 10px;
}
.goto-pro-link .right{
    transform: rotate(-90deg);
}
.pro-feature-description h6 {
    margin: 0px;
    margin-bottom: 30px;
}
.pro-feature {
    margin-right: auto;
}
.pro-feature .header-div {
    margin-top: 60px;
}
.pro-feature .header-div h6 {
    font-size: 1.4rem;
}
.pro-feature .header-div.action i{
    color: red !important;
    font-weight: bold;
}