.static-text h3 {
    display: block;
    line-height: 32px;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 300;
    color: #000;
}
.static-text .input-field h5 {
    padding-left: 0;
    font-size: 15px;
}
li.collection-item .row{
    margin-bottom: 0;
}
.collection .collection-item:last-child {
    border-bottom: none;
    margin-bottom: 90px;
}
.collection.bottom-border {
    border: none;
}
.collection.bottom-border .collection-item:last-child {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 90px;
}
.view-expense .collection .collection-item:last-child {
    border-bottom: none;
    padding: 10px 20px;
}
.overlap-tab-filter div{
    display: flex;
    align-items: center;
    padding-top: 12px;
}
.overlap-tab-filter div div{
    padding-top: 0 !important;
}

.fix-height-tab{
    height: calc(100vh - 175px);
    overflow:auto;
    margin-top: 0;
}
.fix-height-tab.fix-height-tab-account {
    height: calc(100vh - 255px);
}

.height-expenses{
    height: calc(100vh - 217px);
}
.Updated-button{
    margin-top: 10px !important;
    float: left;
    width: 100%;
}
.input-field.static-text.col.s12 h3 {
    margin-bottom: 12px;
}
.Delete-button {
    float: left;
    width: 100%;
    border-top: 1px #ddd solid;
    padding-top: 8px;
    margin-top: 16px;
    border-bottom: solid 1px #ddd;
    padding-bottom: 20px;
}

.border-right .input-field.col.s6:first-child{
    border-right: solid 1px #ddd;
}
.border-right .input-field.col.s6:last-child{
    padding-left: 15px;
}

.Progress {
    width: 100%;
    float: left;

    background: #8ec44e6b;
    height: 5px;
    position: relative;
}

.Progress-Bars  {
    position: absolute;
    top: 0;
    left: 0;
    background: #8ec44e;
    height: 5px;
}
.red-bg{
    background: #ff0101;
    width: 100%;
}
.sweet-alert {
    color: #fff
}
td.right-align.green-color span +span {
    color: green;
}

td.right-align.red-color span +span {
    color: red;
}

h3.green-color span {
    color: green;
}

h3.red-color span {
    color: red;
}

.budget-date-label {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width:100%;
    font-weight: bold;
}

table.budget-no-padding td {
    padding: 0;
    max-width: 0;
}
.budget-detail ul.collection {
    border: 0;
    float: right;
    width: 98%;
}

table.budget-no-padding span.currency {
    color: #000;
}
table.budget-no-padding td.right-align.green-color {
    font-weight: bold;
}
.swal2-actions{
    text-align: right;
}
.swal2-actions button {
    font-weight: normal !important;
}

.swal2-actions button:hover {
    background-image: inherit !important;
}
.swal2-actions button:focus {
    outline: 0;
    box-shadow: inherit;
}
.sweet-alert {
    color: #fff !important;
}
.sweet-alert-container {
    position: fixed !important;
    left: 23px !important;
    bottom: 60px !important;
    max-width: 100% !important;
}